import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import React from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useMainContext } from './MainContext';
import { LocationToggle } from './LocationToggle';

import './App.css';

// the lat/lon for the "center" of NA
const NA_CENTER = [43.47408335, -90.53302776948172];
const customMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

function getIdFromLinkedInURL(url) {
  const items = (url.endsWith('/') ? url.slice(0, -1) : url).split('/')
  return items[items.length - 1];
}

function MyMap() {
  const { cities } = useMainContext()

  const listItems = Object.values(cities).map(city => {
    let posn = [Number(city.metadata.lat), Number(city.metadata.lon)];
    return (
      <Marker position={posn} icon={customMarker} key={city.metadata.name}>
        <Popup>
          <div className="max-h-[300px] overflow-y-auto overflow-x-hidden min-w-[300px]">
            {city.people.map(person => (
              <div className="flex mb-[10px]" style={{ alignItems: 'flex-start' }} key={person.linkedin_url} >
                <div style={{width: '64px', height: '64px' }} >
                    <img
                      src={`profile-photos/${getIdFromLinkedInURL(person.linkedin_url)}.jpeg`}
                      height={64}
                      className="mr-[10px]"
                      alt="I only put this to make npm not throw errors."
                    />
                </div>
                <div style={{ padding: '5px' }}>
                  <div className="h-3" style={{ marginBottom: 0, marginTop: 0 }}>{person.name}</div>
                  <a style={{ marginTop: 5 }} href={person.linkedin_url} target="_blank" rel="noreferrer">[LinkedIn]</a>
                  <p style={{ marginTop: 5, marginBottom: 0, wordBreak: 'break-all', whiteSpace: 'normal' }}>
                    {person.contact_info}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Popup>

      </Marker >
    );
  });

  return (
    <MapContainer center={NA_CENTER} zoom={4} style={{ height: '100vh', width: '100%' }}>
      <LocationToggle />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {listItems}
    </MapContainer>
  );
}

export default MyMap;
