import { useMainContext } from "./MainContext"
import { FALL_TERM, SUMMER_TERM, WINTER_TERM } from "./lib"

const LocationToggle = () => {
    return (
        <div className="z-[999] absolute right-5 top-[20px] p-3 rounded flex flex-col justify-center items-center">
            <div className="flex space-x-2">
                {[WINTER_TERM, SUMMER_TERM, FALL_TERM].map(term => (
                    <RadioButton key={term} term={term} />
                ))}
            </div>
        </div>
    );
};

const RadioButton = ({ term }) => {
    const { selectedTerm, setSelectedTerm } = useMainContext();
    const selected = selectedTerm === term;
    return (
        <button
            className={`${selected ? 'bg-green-500 text-black' : 'bg-black text-green-500'
                } font-bold px-4 py-2 rounded-full border-none focus:outline-none transition duration-300`}
            onClick={() => setSelectedTerm(term)}
        >
            {term.toUpperCase()} 2024
        </button>
    );
};



export { LocationToggle }
