import { createContext, React, useState, useEffect, useContext } from "react"
import { fetchMapData, WINTER_TERM } from "./lib"


const NOT_SURE = "Not Sure!"
const MainContext = createContext()

const MainContextProvider = ({ children }) => {
    const [people, setPeople] = useState([])
    const [cities, setCities] = useState({})
    const [selectedTerm, setSelectedTerm] = useState(WINTER_TERM)

    useEffect(() => {
        fetchMapData('https://api.maps.grandrivertrading.ca/data.json').then(data => {
            setPeople(data)
        })
    }, [])

    useEffect(() => {
        let cities = {}
        people.forEach(person => {
            const city = person[`city_${selectedTerm}`];
            if (!(city.name in cities)) {
                cities[city.name] = {
                    metadata: city,
                    people: []
                }
            }

            cities[city.name].people.push(person);
        })
        delete cities[NOT_SURE]
        setCities(cities)
    }, [selectedTerm, people])

    const onSetSelectedTerm = (newSelectedTerm) => {
        if (selectedTerm === newSelectedTerm) {
            return;
        }


        setSelectedTerm(newSelectedTerm)
    }

    return (
        <MainContext.Provider value={{
            cities,
            selectedTerm,
            setSelectedTerm: onSetSelectedTerm
        }}>
            {children}
        </MainContext.Provider>
    )

}

const useMainContext = () => {
    const mapContext = useContext(MainContext)

    return mapContext
}


export { MainContext, MainContextProvider, useMainContext }
