import StaticData from "./data.json"

const WINTER_TERM = "winter"
const SUMMER_TERM = "summer"
const FALL_TERM = "fall"

async function fetchMapData(url) {
    if (process.env.NODE_ENV === 'development') {
        return StaticData
    }
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error.message);
        return [];
    }
}

export { fetchMapData, WINTER_TERM, SUMMER_TERM, FALL_TERM }

